.wrapper {
  transform: scale(0.8);
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: -1000px; */
  margin: 0 auto;
}
.home-page {
  /* padding: 10px; */
  margin-top: -250px;
  width: 100%;
  background-color: black;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.latest-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.articles-layout {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.nF-layout {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  width: 1200px;
  align-items: flex-start;
}

.side-articles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 1200px;
  align-items: center;
}