.password-wall {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .password-wall form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .password-wall label {
    color: black;
  }
  .text{
      margin-right: 10px;
  }
  
  .password-wall input {
    margin: 10px 0;
    padding: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .password-wall button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .password-wall button:hover {
    background-color: #0056b3;
  }
  