.article-link {
  text-decoration: none;
  color: inherit;
}

.article-block {
  display: flex;
  flex-direction: row;
  border-top: 1px solid grey;
  padding: 15px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
  width: 1000px;
  text-overflow: ellipsis;
}

.article-block:hover {
  box-shadow: 0 0px 8px purple;
}

.article-block-large {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #6c35de;
  padding: 10px;
  transition: box-shadow 0.3s ease;
  grid-column: span 2; /* Make the first article span two columns */
  width: 700px;
  height: 650px;
  padding: 30px;
  border-radius: 10px;

}
.article-block-large:hover {
  box-shadow: 0 0px 8px whitesmoke;
}

.thumb-large {
  width: 100%;
  margin-bottom: 2px;
  object-fit: fill;
  aspect-ratio: 12/9; /* Set aspect ratio for the images */
}
.thumb-side {
  height: 80px;
  width: 80px;
  margin-right: 30px;
}

.article-title-large {
  font-size: 1.5rem;
  margin-bottom: 10px;
  /* padding: 10px; */
}

.beat {
  font-size: 14px;
  font-weight: bold;
  color: #6c35de; 
  text-transform: uppercase;
  margin-bottom: 3px;
}
.title {
  font-size: 30px;
  margin: 5px 0;
}

.meta-info {
  font-size: 12px;
  color: gray;
}

.meta-info .by,
.meta-info .date {
  display: block;
}
.meta-info .toolTag {
  display: block;
  color: #CCFF00;
  border-width: 1px;
  border-radius: 10px;
}
.side-articles .thumbnail {
  height: 100px; /* Smaller height for side article thumbnails */
}

/* Adjust the text size for side article titles */
.side-articles .title {
  font-size: 1rem;
}

.toolTag {
  font-size: 14px;
  font-weight: bold;
  color: #CCFF00; /* Adjust color to match your theme */
  text-transform: uppercase;
  margin-top: 10px;
  border-width: 1px;
  border-radius: 10px;
  border-color: black;
}

.nFtoolTag {
  font-size: 14px;
  font-weight: bold;
  color: #CCFF00; /* Adjust color to match your theme */
  text-transform: uppercase;
  margin-top: 15px;

}
