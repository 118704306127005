.wrapper{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  }


.beat-page {
    font-family: 'Arial', sans-serif;
    color: #FFFFFF;
    background: black;
    padding: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
  }
  
  .beat-page h1 {
    font-size: 2em;
    margin-bottom: 30px;
    margin-left: 8vw;
  }
  
  .articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .featured-article {
    flex-basis: 40%;
    background: #181818;
    padding: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: fit-content;
    border-top: 1px solid #6C35DE;
    border-radius: 10px;
  }
  
  .featured-article img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  
  .featured-article-header {
    font-size: 1.5em;
    margin-bottom: 20px;
    margin-top: -10px;
  }
  
  .article-meta {
    color: grey;
    font-size: 0.85em;
    margin-bottom: 10px;
  }
  .toolTag{
    color: #6c35de;
    font-size: 0.85em;
    margin-bottom: 15px;
  }
  
  .article-abstract {
    font-size: 1em;
    line-height: 1.5;
  }
  
  .small-featured-articles {
    flex-basis: 35%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    
  }
  
  .small-article {
    background: #181818;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #6C35DE;
    border-radius: 10px;
    margin-top: -10px;
  }
  
  .small-article img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    object-fit: contain;

    /* padding: 10px; */
  }
  
  .small-article:nth-child(2) img {
    display: none; /* Hide image for all but first article */
  }
  
  .article-list {
    width: 100%;
  }
  
  .article-list-item {
    border-bottom: 1px solid #ddd;
    padding: 5px ;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    background-color: black;
    border-radius: 10px;
    color: aliceblue;
  }
  
  .article-list-item:last-child {
    border-bottom: none;
  }
  
  .article-list-item-title {
    font-size: 1em;
    color: aliceblue;
    margin-bottom: 5px;
    margin-top: -2px;
  }
  
  .article-list-item-meta {
    color: #666;
    font-size: 0.85em;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .articles-container {
      flex-direction: column;
    }
  
    .featured-article, .small-featured-articles {
      flex-basis: 100%;
    }
  }
  