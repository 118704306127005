.article-details {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .article-details h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .article-details p {
    font-size: 1.5em;
    color: white;
  }
  
  .article-details img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }